import request from '@/api/request.js'

// 获取机器人列表
export function getBotList (data) {
  return request({
    url: '/api/robot/botList',
    method: 'POST',
    data
  })
}
// 添加机器人
export function addRobot (data) {
  return request({
    url: '/api/robot/bindBot',
    method: 'post',
    data
  })
}

// 付款
export function robotbuyorder (data) {
  return request({
    url: '/api/robotbuyorder/create/order',
    method: 'post',
    data
  })
}
// 付款记录
export function getBuyList (data) {
  return request({
    url: '/api/robotbuyorder/get',
    method: 'GET',
    data
  })
}

// 联系人
export function contactFun (data) {
  return request({
    url: '/api/robot/contacts',
    method: 'post',
    data
  })
}

// 渠道商
export function inviteRetailerFun (data) {
  return request({
    url: '/api/robotGroup/inviteRetailer',
    method: 'post',
    data
  })
}
// 群组
export function groupFun (data) {
  return request({
    url: '/api/robot/groups',
    method: 'post',
    data
  })
}
// 群成员
export function groupMember (data) {
  return request({
    url: '/api/robot/groupMembers',
    method: 'post',
    data
  })
}
// 添加订单处理/销售报表接收人
export function setReceiver (data) {
  return request({
    url: '/api/robot/setReceiver',
    method: 'post',
    data
  })
}
// 查询订单处理/销售报表接收人
export function listReceiver (data) {
  return request({
    url: '/api/robot/listReceiver',
    method: 'post',
    data
  })
}
// 删除订单处理/销售报表接收人
export function deleteReceiver (data) {
  return request({
    url: '/api/robot/deleteReceiver',
    method: 'post',
    data
  })
}
// 查询渠道群
export function listRetailerGroups (data) {
  return request({
    url: '/api/robotGroup/listRetailerGroups',
    method: 'post',
    data
  })
}
// 查询渠道详情
export function retailGroupDetail (data) {
  return request({
    url: '/api/robotGroup/retailGroupDetail',
    method: 'post',
    data
  })
}

// 删除渠道群
export function deleteGroups (data) {
  return request({
    url: '/api/robotGroup/delete',
    method: 'post',
    data
  })
}
// 微信消息
export function robotMessage (data) {
  return request({
    url: '/api/robotMessage/search',
    method: 'post',
    data
  })
}

// 重发微信消息
export function repush (data) {
  return request({
    url: '/api/robotMessage/push',
    method: 'post',
    data
  })
}

// 标记消息已处理
export function mark (data) {
  return request({
    url: '/api/robotMessage/mark',
    method: 'post',
    data
  })
}

// 配置渠道群
export function configRetailerGroup (data) {
  return request({
    url: '/api/robotGroup/configRetailerGroup',
    method: 'post',
    data
  })
}

// 查询供应商群
export function listSupplierGroups (data) {
  return request({
    url: '/api/robotGroup/listSupplierGroups',
    method: 'post',
    data
  })
}

// 配置供应商群
export function configSupplierGroup (data) {
  return request({
    url: '/api/robotGroup/configSupplierGroup',
    method: 'post',
    data
  })
}

// 供应商群详情
export function supplierGroupDetail (data) {
  return request({
    url: '/api/robotGroup/supplierGroupDetail',
    method: 'post',
    data
  })
}

// 批量创建群
export function batchCreateGroup (data) {
  return request({
    url: '/api/robotGroup/batchCreateGroup',
    method: 'post',
    data
  })
}

// 批量设置禁收文件关键词
export function batchSetKeywords (data) {
  return request({
    url: '/api/robotGroup/batchSetKeywords',
    method: 'post',
    data
  })
}
