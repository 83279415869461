<template>
  <div>
    <div class="contents">
    <div class="mainbox">
       <div class="jiqiren"><span>机器人微信号：</span>{{shopArr.name}} <span>的活跃群</span></div>
       <div class="btns">
         <el-button class="btn el-icon-plus" type="primary" @click="batchCreate" v-if="role=='supplier'">批量创建渠道收单群</el-button>

         <el-button class="btn el-icon-plus" type="primary" @click="batchCreate" v-if="role=='retailer'">批量创建供应商对接群</el-button>

       </div>
       <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="50" header-align="center" align="center">
        </el-table-column>
        <el-table-column
        label="序号"
        type="index"
        width="50"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="群名称"
          width="200">
        </el-table-column>
        <!-- <el-table-column
          prop="id"
          label="群主"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="群状态"
          >
        </el-table-column>
         <el-table-column
          prop="status"
          label="建议邀请状态"
          >
        </el-table-column> -->
         <el-table-column
      label="操作"
        header-align="center" align="center">
      <template slot-scope="scope">
        <!-- <el-button @click="loginClick(scope.row)" type="text" size="small">发送邀请</el-button> -->
        <el-button 
        v-if="role=='supplier'"
        @click="channelClick(scope.row)" type="text" size="small">创建渠道收单群</el-button>

        <el-button
        v-if="role=='retailer'"
         @click="supplierClick(scope.row)" type="text" size="small">创建供应商对接群</el-button>
        <!-- <el-button @click="qunClick(scope.row)" type="text" size="small">不邀请</el-button> -->
      </template>
    </el-table-column>
      </el-table>
      <!-- <div class="paginations">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="changePage" :current-page="page" :page-size="pageSize"
            :total="total">
          </el-pagination>
      </div> -->
      </div>
    </div>

     <el-dialog title="扫码创建机器人" :visible.sync="shopflag" :before-close="handleClose" width="600px">
       <div class="shopLogo" v-loading="loading">
          <div class="shopol" ref="qrCodeUrl"></div>
          <p>请用微信扫一扫</p>
       </div> 
    </el-dialog>
  </div>
</template>

<script>
import QRCode from'qrcodejs2'
import { groupFun, scanBarCode, robotisLogin, addRobot, batchCreateGroup } from "@/api/robot";
import { MessageBox } from 'element-ui'
import passWord from "@/components/password";
let moment = require('moment');
let timeVal
export default {
  name: 'Orderss',
  components: { passWord },
  data() {
    return {
      searchForm: {
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,
      shopArr: [],
      loading: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: [],
      role: window.sessionStorage.getItem("role"),
    }
  },
  methods: {
    channelClick(row){
      this.$router.push({
        path: "/channel/detail?qid=" + encodeURI(row.id) + "&name=" + this.shopArr.botId
      })
    },
    supplierClick(row) {
      this.$router.push({
        path: "/suppliergroup/detail?qid=" + encodeURI(row.id) + "&name=" + this.shopArr.botId
      })
    },
    handleClose(){
      this.shopflag = false;
      clearInterval(timeVal)
    },
    batchCreate() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请选择要创建的群")
        return
      }
      const param = {
        role: this.role,
        robotId: this.shopArr.id,
        groups: this.multipleSelection.map(it => {
          return {"code":it.id, "name": it.name}
        })
      }
      batchCreateGroup(param).then(res => {
        if(res.code === 200) {
          this.$message.success("请求成功，请到群管理页面查看创建结果");
          this.getTopSearch()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTopSearch() {
      this.getGroup(this.shopArr.botId)
    },
    getGroup(botId){
      var that = this
      var datas = {
        botId: botId
      }
      groupFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
         that.tableData = res.data.groups;
         that.shopArr= res.data.robot
        }else{
          that.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    shouquan(){
      var that = this
      that.shopflag = true;
      that.getPlat()
    },
    loginClick(row){
      var that = this
      that.shopflag = true;
      that.chongPlat(row)
    }
  },
  mounted(){
    var botId = this.$route.query.id
    console.log(botId)
    this.getGroup(botId)
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.jiqiren{
  padding: 0px 0 20px;
  display: block;
  font-size: 18px;
  line-height:40px;
  color: #07C160;
  font-weight: bold;
}
.jiqiren span{
  color: #999;
  font-weight: normal;
}
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.shopLogo{
  display: block;
  overflow: hidden;
}
.shopLogo p{
  line-height:44px;
  display: block;
  text-align: center;
  height: 44px;
}
.shopol{
  width: 200px;
  height: 200px;
  border: 1px solid #eee;
  padding: 10px;
  margin: 0 auto 10px;
  cursor: pointer;
  background-color:#fff; 
  box-sizing: border-box;
  display: block;
  overflow: hidden;
}
.btn {
  margin: 0px 15px 20px 0px;
}
</style>